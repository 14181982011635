import React, {Fragment, useEffect, useState} from 'react'
import {Combobox, Listbox, Transition} from '@headlessui/react'
import {classNames} from "../helpers/helpers";
import {CheckIcon, ExclamationIcon, SelectorIcon} from "@heroicons/react/solid";
import Spinner from "../components/spinner";
import {formatAddress} from "../utils";

// partyType = "assignee" | "debtor"
export default function PartySelector({companies, partyType, onSubmit, serverData}) {
    const [query, setQuery] = useState("")
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedBankAccount, setSelectedBankAccount] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setQuery("")
        setSelectedItem(null)
    }, [])

    /*useEffect(() => {
        if (onSubmit && selectedItem?.id && selectedItem?.id !== serverData?.[partyType]?.oid && !isSubmitting) {

        }
    }, [selectedItem, selectedBankAccount, serverData, isSubmitting])*/

    function onInputChange(e) {
        setQuery(e.target.value)
    }

    function onSelectedItem(item) {
        setSelectedItem(item)

        if (partyType === "assignee") {
            const bankAccount = item?.bankAccounts?.find(ba => ba.main) || item?.bankAccounts?.[0]
            if (bankAccount) {
                setSelectedBankAccount(bankAccount)
                submit(item, bankAccount)
                return
            }
        }

        submit(item)
    }

    function onSelectedBankAccount(bankAccount) {
        setSelectedBankAccount(bankAccount)
        submit(selectedItem, bankAccount)
    }

    function submit(selectedItem, selectedBankAccount) {
        if (partyType === "assignee" && !selectedBankAccount) return

        const updates = {
            ...selectedItem,
            id: "" + selectedItem.id,
        }

        if (selectedBankAccount) updates.bankAccounts = [{...selectedBankAccount, main: true}]

        setIsSubmitting(true)
        onSubmit(partyType, updates, (ok, error) => {
            if (!ok) {
                alert("Error: " + (error || "Something went wrong"))
            }
            setIsSubmitting(false)
        })
    }

    function renderPartyName(partyData) {
        let label = "Name", value = "N/A", link, className = "text-gray-900",
            iconError = false, iconOk = false
        if (partyData?.oid && (partyData?.shortName || partyData?.name)) {
            // has both, all OK
            value = partyData.shortName || partyData.name
            className = "text-gray-900 underline"
            link = "#investmentCompanies/" + partyData.oid
            iconOk = true
        } else if (partyData?.oid) {
            label = "Company ID"
            value = partyData.oid + " (Company doesn't exist?)"
            className = "text-red-500"
            iconError = true
        } else {
            iconError = true
        }

        if (
            (!serverData?.[partyType]?.emailInvoices || !serverData?.[partyType]?.emailDocuments) && (
                ((serverData?.type === "F" || serverData?.type === "IF") && partyType === "assignee") ||
                ((serverData?.type === "RF") && partyType === "debtor")
            )
        ) {
            iconOk = false
            iconError = true

            className = link ? "text-red-500 underline" : "text-red-500"
        }


        return (
            <div
                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{label}</dt>
                <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0 relative pr-8">
                    <a
                        className={classNames(
                            "block truncate",
                            className,
                        )}
                        href={link}
                    >
                        {value}
                    </a>
                    {iconError && (
                        <div
                            className="absolute right-1 top-0 flex items-center">
                            <ExclamationIcon
                                className="h-5 w-5 text-red-600"/>
                        </div>
                    )}
                    {iconOk && (
                        <div
                            className="absolute right-1 top-0 flex items-center">
                            <CheckIcon className="h-5 w-5 text-green-600"/>
                        </div>
                    )}
                </dd>
            </div>
        )
    }

    const filtered = companies?.length > 0 ?
        (query === ''
                ? [...companies]
                : [...companies.filter(item => {
                    return item.name.toLowerCase().includes(query.toLowerCase())
                })]
        )
        : []

    return (
        <>
            <div className="bg-white px-4 pb-4 sm:px-6 sm:pb-4">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                        <Combobox as="div" value={selectedItem} onChange={onSelectedItem}>
                            <Combobox.Label className="block text-sm font-medium text-gray-700">Company
                                or organization name</Combobox.Label>
                            <div className="relative mt-1">
                                <Combobox.Input
                                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm"
                                    onChange={onInputChange}
                                    displayValue={(person) => person?.name || ""}
                                    autoComplete="off"
                                    placeholder="Type to search..."
                                />

                                {isSubmitting && (
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-6 flex items-center pr-3">
                                        <Spinner button className="text-gray-300"/>
                                    </div>
                                )}

                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"/>
                                </Combobox.Button>

                                {filtered?.length > 0 && (
                                    <Combobox.Options
                                        className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <>
                                            {filtered.map((item) => item?.name && (
                                                <Combobox.Option
                                                    key={item.id}
                                                    value={item}
                                                    className={({active}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            active ? 'bg-gray-600 text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({active, selected}) => (
                                                        <>
                                                            <div className="flex">
                                                                            <span
                                                                                className={classNames('truncate', selected && 'font-semibold')}>{item.isCustom ? `Use "${item.name}"` : item.name}</span>
                                                                <span
                                                                    className={classNames(
                                                                        'ml-2 truncate text-gray-500',
                                                                        active ? 'text-gray-200' : 'text-gray-500'
                                                                    )}
                                                                >{item.taxNumber}</span>
                                                            </div>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        active ? 'text-white' : 'text-gray-600'
                                                                    )}
                                                                ><CheckIcon className="h-5 w-5"
                                                                            aria-hidden="true"/></span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </>
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                    </div>
                    {partyType === "assignee" && (
                        <div className="sm:col-span-6">
                            <Listbox value={selectedBankAccount} onChange={onSelectedBankAccount}>
                                {({open}) => (
                                    <>
                                        <Listbox.Label
                                            className="block text-sm font-medium text-gray-700">
                                            Bank account
                                        </Listbox.Label>
                                        <div className="relative mt-1">
                                            <Listbox.Button
                                                className={classNames(
                                                    "relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm",
                                                    selectedItem?.bankAccounts?.length > 0 ? "bg-white" : "bg-gray-50"
                                                )}>
              <span className="inline-flex w-full truncate">
                  {selectedBankAccount ? (
                      <>
                          <span className="truncate">{selectedBankAccount?.bank}</span>
                          <span
                              className="ml-2 truncate text-gray-500">{selectedBankAccount?.accountNumber || ""} {selectedBankAccount?.main && (" (main)")}</span>
                      </>
                  ) : "Select..."}
              </span>
                                                <span
                                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options
                                                    className="absolute z-10 mt-1 max-h-24 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {selectedItem?.bankAccounts?.length > 0 && selectedItem.bankAccounts.map((item, index) => (
                                                        <Listbox.Option
                                                            key={index}
                                                            className={({active}) =>
                                                                classNames(
                                                                    active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={item}
                                                        >
                                                            {({selected, active}) => (
                                                                <>
                                                                    <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {item.bank}
                          </span>
                                                                        <span
                                                                            className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {item.accountNumber} {item.main && (" (main)")}
                          </span>
                                                                    </div>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-gray-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                    )}
                </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    {renderPartyName(serverData?.[partyType])}

                    {partyType === "assignee" ? (
                        <div
                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Bank account
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                {serverData?.assignee?.bankAccount?.number || "N/A"} ({serverData?.assignee?.bankAccount?.bankName || "N/A"})
                            </dd>
                        </div>
                    ) : (
                        <div
                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                MEP
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                {serverData?.maxExtensionPeriod ? serverData?.maxExtensionPeriod + " days" : "-"}
                            </dd>
                        </div>
                    )}
                    <div
                        className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Tax number</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                            {serverData?.[partyType]?.vatNo || serverData?.[partyType]?.taxNo || "N/A"}
                        </dd>
                    </div>
                    <div
                        className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Address</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                            {formatAddress(serverData?.[partyType]?.address) || "N/A"}
                        </dd>
                    </div>

                    {(((serverData?.type === "F" || serverData?.type === "IF") && partyType === "assignee") || ((serverData?.type === "RF") && partyType === "debtor")) && (
                        <div
                            className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 truncate">
                                <ul>
                                    <li>
                                        Invoices:{" "}
                                        {serverData?.[partyType]?.emailInvoices || (
                                            <span className="text-red-500 italic">N/A</span>
                                        )}
                                    </li>
                                    <li>
                                        Documents:{" "}
                                        {serverData?.[partyType]?.emailDocuments || (
                                            <span className="text-red-500 italic">N/A</span>
                                        )}
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    )}

                </dl>
            </div>
        </>
    )
}
