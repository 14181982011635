import React, {useCallback, useEffect, useRef, useState} from "react"
import {useLogin} from "react-admin"
import QRCode from "react-qr-code"
import Spinner from "../components/spinner"
import {
    getMultiFactorResolver,
    multiFactor,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    TotpMultiFactorGenerator
} from "firebase/auth"
import {firebaseAuth} from "../firebase"
import {getRecaptchaVerifier} from "../App"
import {ENVIRONMENT, VERSION} from "../config"

const ViewState = {
    CREDENTIALS: 'credentials',
    TOTP: 'totp',
    SMS: 'sms',
    TOTP_ENROLL: 'totp-enroll',
    MFA_SELECT: 'mfa-select',
}

const FormEvent = {
    onInit: 'onInit',
    onChange: 'onChange',
    onSubmit: 'onSubmit',
    onSelect: 'onSelect',
    onCancel: 'onCancel',
    onError: 'onError',
}

const MfaType = {
    TOTP: 'totp',
    SMS: 'sms',
}

export function useLoginView(callback, redirectPath) {
    const [view, setView] = useState(ViewState.CREDENTIALS)
    const [multiFactorResolver, setMultiFactorResolver] = useState(null)
    const [verificationId, setVerificationId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [hasMultipleMultiFactor, setHasMultipleMultiFactor] = useState(false)
    const [error, setError] = useState('')
    const reactAdminLogin = useLogin()

    const mounted = useRef(false)

    useEffect(() => {
        setView(ViewState.CREDENTIALS)
        setMultiFactorResolver(null)
        setVerificationId("")
        setIsLoggedIn(false)
        setError('')
        mounted.current = true
        return () => { mounted.current = false }
    }, [])

    const stopLoading = useCallback(() => {
        setTimeout(() => {
            if (mounted.current) setIsLoading(false)
        }, 3000)
    }, [])

    useEffect(() => {
        setIsLoading(false)
    }, [view])

    async function login(cred) {
        return reactAdminLogin(cred, redirectPath).then(res => {
            setIsLoggedIn(true)
            callback && callback(res)
            return res
        })
    }

    const onFormEvent = (eventType, data = null) => {
        if (eventType === FormEvent.onChange || eventType === FormEvent.onSubmit) setError('')

        switch (view) {
            case ViewState.CREDENTIALS:
                if (eventType === FormEvent.onSubmit) submitCredentials(data)
                break

            case ViewState.TOTP:
                if (eventType === FormEvent.onSelect) setView(ViewState.MFA_SELECT)
                else if (eventType === FormEvent.onCancel) mfaReset()
                else if (eventType === FormEvent.onSubmit) submitMfaCode({mfaType: MfaType.TOTP, ...data})
                break

            case ViewState.SMS:
                if (eventType === FormEvent.onInit) sendSms()
                else if (eventType === FormEvent.onSelect) setView(ViewState.MFA_SELECT)
                else if (eventType === FormEvent.onCancel) mfaReset()
                else if (eventType === FormEvent.onSubmit) submitMfaCode({mfaType: MfaType.SMS, ...data})
                break

            case ViewState.TOTP_ENROLL:
                if (eventType === FormEvent.onCancel) mfaReset()
                else if (eventType === FormEvent.onSubmit) enrollMfa(data)
                break

            case ViewState.MFA_SELECT:
                if (eventType === FormEvent.onCancel) mfaReset()
                else if (eventType === FormEvent.onSubmit) selectMfa(data)
                break

            default:
                // Nothing to do here
                break
        }
    }

    const submitCredentials = async ({email, password}) => {
        try {
            setIsLoading(true)

            await login({username: email, password})
                .catch(async err => handleError(err))
        } finally {
            stopLoading()
        }
    }

    const enrollMfa = async ({verificationCode, totpSecret}) => {
        try {
            setIsLoading(true)

            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
                totpSecret,
                verificationCode
            )

            await multiFactor(firebaseAuth.currentUser).enroll(multiFactorAssertion, "Totp")

            setView(ViewState.CREDENTIALS)
        } finally {
            stopLoading()
        }
    }

    const showDefaultMfa = err => {
        const resolver = getMultiFactorResolver(firebaseAuth, err)
        if (!resolver.hints[0] || !resolver.session) {
            setError('Error resolving MFA')
            return
        }

        setHasMultipleMultiFactor(resolver.hints.length > 1)
        setMultiFactorResolver(resolver)

        const totpIndex = resolver.hints.findIndex(e => e.factorId === TotpMultiFactorGenerator.FACTOR_ID)
        const smsIndex = resolver.hints.findIndex(e => e.factorId === PhoneMultiFactorGenerator.FACTOR_ID)

        // We default to TOTP, if available
        if (totpIndex > -1) setView(ViewState.TOTP)
        else if (smsIndex > -1) setView(ViewState.SMS)
        else setError('Unsupported MFA')
    }

    const sendSms = async () => {
        const phoneInfoOptions = {
            multiFactorHint: multiFactorResolver.hints[0],
            session: multiFactorResolver.session
        }

        const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth)
        await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, getRecaptchaVerifier())
            .then(verificationId => setVerificationId(verificationId))
            .catch(err => {
                console.error("verifyPhoneNumber error:", err)
                setError(err.message)
            })
    }

    const submitMfaCode = async ({mfaType, code}) => {
        try {
            setIsLoading(true)

            let multiFactorAssertion = null

            switch (mfaType) {
                case MfaType.TOTP:
                    const totpMfa = multiFactorResolver.hints.find(e => e.factorId === TotpMultiFactorGenerator.FACTOR_ID)
                    multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpMfa.uid, code)
                    break
                case MfaType.SMS:
                    const cred = PhoneAuthProvider.credential(verificationId, code)
                    multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
                    break
                default:
                    setError('Unknown MFA')
                    return
            }

            await multiFactorResolver.resolveSignIn(multiFactorAssertion)
                .then(userCredential => {
                    const token = userCredential?.idToken || userCredential?.user?.accessToken

                    login({username: userCredential.user.email, idToken: token})
                        .catch(err => handleError(err))
                })
                .catch(err => handleError(err))
        } finally {
            stopLoading()
        }
    }

    const mfaReset = (errorMessage = '') => {
        setView(ViewState.CREDENTIALS)
        setError(errorMessage || '')
    }

    const selectMfa = ({mfaType}) => {
        switch (mfaType) {
            case MfaType.TOTP:
                setView(ViewState.TOTP)
                break
            case MfaType.SMS:
                setView(ViewState.SMS)
                break
            default:
                setError('Unknown MFA selected')
                break
        }
    }

    const handleError = (err) => {
        console.log(err)

        const errorCode = err.code || err.body?.error
        const errorMessage = err.message || err.body?.error_description

        switch (errorCode) {
            case "auth/enroll-mfa":
                setView(ViewState.TOTP_ENROLL)
                break
            case "auth/multi-factor-auth-required":
                showDefaultMfa(err)
                break
            case "auth/user-disabled":
                setError('Account has been disabled')
                break
            case "auth/wrong-password":
                setError('Invalid email or password')
                break
            case "auth/totp-challenge-timeout":
                mfaReset('You need to login again, TOTP session expired')
                break
            case "auth/requires-recent-login":
                setError(errorMessage)
                break
            default:
                console.error(err)
                setError(errorMessage)
        }
    }

    function renderView() {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                {view === ViewState.CREDENTIALS &&
                    <LoginForm isLoading={isLoading} error={error} onEvent={onFormEvent}/>}
                {view === ViewState.TOTP_ENROLL &&
                    <TotpEnrollForm isLoading={isLoading} error={error} onEvent={onFormEvent}/>}
                {view === ViewState.TOTP &&
                    <TotpForm isLoading={isLoading} hasMultipleMultiFactor={hasMultipleMultiFactor} error={error}
                              onEvent={onFormEvent}/>}
                {view === ViewState.SMS &&
                    <SmsForm isLoading={isLoading} hasMultipleMultiFactor={hasMultipleMultiFactor} error={error}
                             onEvent={onFormEvent}/>}
                {view === ViewState.MFA_SELECT &&
                    <MfaSelectionForm isLoading={isLoading} error={error} onEvent={onFormEvent}/>}
                <div className="mt-16 mb-8">
                    <span className="text-gray-400">v{VERSION}</span>
                </div>
            </div>
        )
    }

    return [
        renderView,
        isLoggedIn
    ]
}


const LoginPage = () => {
    const [renderView] = useLoginView()

    return renderView()
}

const LoginForm = ({isLoading, error, onEvent}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const textChange = (e, action) => {
        onEvent(FormEvent.onChange)
        action(e.target.value)
    }

    const submit = e => {
        e.preventDefault()
        onEvent(FormEvent.onSubmit, {email, password})
    }

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg px-4 py-8">
            <h1 className="text-center text-xl font-bold mb-4 text-gray-900">Login</h1>
            <form onSubmit={submit}>
                <div className="flex justify-between items-center w-full mb-4">
                    <label htmlFor="email" className="text-gray-700">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        autoComplete="on"
                        autoFocus={true}
                        onChange={e => textChange(e, setEmail)}/>
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <label htmlFor="password" className="text-gray-700">Password</label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={e => textChange(e, setPassword)}/>
                </div>
                <div className="flex justify-center items-center w-full mb-4">
                    <LoadingSubmitButton text="Login" isLoading={isLoading} />
                </div>
                <div className="text-center text-red-500 mb-4">{error}</div>
            </form>
        </div>
    )
}

const TotpForm = ({isLoading, hasMultipleMultiFactor, error, onEvent}) => {
    const [mfaCode, setMfaCode] = useState('')

    const textChange = (e, action) => {
        onEvent(FormEvent.onChange)
        action(e.target.value)
    }

    const select = e => {
        e.preventDefault()
        onEvent(FormEvent.onSelect)
    }

    const submit = e => {
        e.preventDefault()
        onEvent(FormEvent.onSubmit, {code: mfaCode})
    }

    const cancel = e => {
        e.preventDefault()
        onEvent(FormEvent.onCancel)
    }

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg px-4 py-8">
            <h1 className="text-center text-xl font-bold mb-4 text-gray-900">Two-factor authentication required</h1>
            <form onSubmit={submit}>
                <div className="flex justify-between items-center w-full mb-4">
                    Generate a code in your authentication app and enter it here.
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <input
                        id="mfaCode"
                        name="mfaCode"
                        type="text"
                        placeholder="Enter code"
                        value={mfaCode}
                        autoComplete="off"
                        autoFocus={true}
                        onChange={e => textChange(e, setMfaCode)}/>
                    {hasMultipleMultiFactor && <button className="text-blue-500 bg-white border-transparent text-sm" type="button" onClick={select}>Choose different authentication</button>}
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <button type="button" className="text-white bg-gray-500 hover:bg-gray-600 font-medium rounded-lg py-2 px-4" onClick={cancel}>Cancel</button>
                    <LoadingSubmitButton text="Login" isLoading={isLoading} />
                </div>
                <div className="text-center text-red-500 mb-4">{error}</div>
            </form>
        </div>
    )
}

const SmsForm = ({isLoading, hasMultipleMultiFactor, error, onEvent}) => {
    const [mfaCode, setMfaCode] = useState('')
    const isInitialized = useRef(false)

    useEffect(() => {
        if (!isInitialized.current) {
            isInitialized.current = true
            onEvent(FormEvent.onInit)
        }
    }, [])

    const textChange = (e, action) => {
        onEvent(FormEvent.onChange)
        action(e.target.value)
    }

    const select = e => {
        e.preventDefault()
        onEvent(FormEvent.onSelect)
    }

    const submit = e => {
        e.preventDefault()
        onEvent(FormEvent.onSubmit, {code: mfaCode})
    }

    const cancel = e => {
        e.preventDefault()
        onEvent(FormEvent.onCancel)
    }

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg px-4 py-8">
            <h1 className="text-center text-xl font-bold mb-4 text-gray-900">Two-factor authentication required</h1>
            <form onSubmit={submit}>
                <div className="flex justify-between items-center w-full mb-4">
                    Enter the code we sent to your phone by text message (SMS).
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <input
                        id="mfaCode"
                        name="mfaCode"
                        type="text"
                        placeholder="Enter code"
                        value={mfaCode}
                        autoComplete="off"
                        autoFocus={true}
                        onChange={e => textChange(e, setMfaCode)}/>
                    {hasMultipleMultiFactor && <button className="text-blue-500 bg-white border-transparent text-sm" type="button" onClick={select}>Choose different authentication</button>}
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <button type="button" className="text-white bg-gray-500 hover:bg-gray-600 font-medium rounded-lg py-2 px-4" onClick={cancel}>Cancel</button>
                    <LoadingSubmitButton text="Login" isLoading={isLoading} />
                </div>
                <div className="text-center text-red-500 mb-4">{error}</div>
            </form>
        </div>
    )
}

const TotpEnrollForm = ({isLoading, error, onEvent}) => {
    const [url, setUrl] = useState('')
    const [secret, setSecret] = useState(null)
    const [mfaCode, setMfaCode] = useState('')

    useEffect(() => {
        multiFactor(firebaseAuth.currentUser).getSession()
            .then(multiFactorSession => TotpMultiFactorGenerator.generateSecret(multiFactorSession))
            .then(totpSecret => {
                setSecret(totpSecret)
                console.log(firebaseAuth.currentUser.id)
                setUrl(totpSecret.generateQrCodeUrl(firebaseAuth.currentUser.id, `NeksterAdmin-${ENVIRONMENT}`))
            })
    }, [])

    const textChange = (e, action) => {
        onEvent(FormEvent.onChange)
        action(e.target.value)
    }

    const submit = e => {
        e.preventDefault()
        onEvent(FormEvent.onSubmit, {verificationCode: mfaCode, totpSecret: secret})
    }

    const cancel = e => {
        e.preventDefault()
        onEvent(FormEvent.onCancel)
    }

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg px-4 py-8">
            <h1 className="text-center text-xl font-bold mb-4 text-gray-900">Enroll 2-step</h1>
            <form onSubmit={submit}>
                <div className="flex justify-between items-center w-full mb-4">
                    <label className="text-gray-700">Secret</label>
                    {url && <QRCode value={url} />}
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <label htmlFor="mfaCode" className="text-gray-700">Code</label>
                    <input
                        id="mfaCode"
                        name="mfaCode"
                        type="text"
                        value={mfaCode}
                        onChange={e => textChange(e, setMfaCode)}/>
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <button type="button" className="text-white bg-gray-500 hover:bg-gray-600 font-medium rounded-lg py-2 px-4" onClick={cancel}>Cancel</button>
                    <LoadingSubmitButton text="Enroll" isLoading={isLoading} />
                </div>
                <div className="text-center text-red-500 mb-4">{error}</div>
            </form>
        </div>
    )
}

const MfaSelectionForm = ({error, onEvent}) => {
    const submit = e => {
        e.preventDefault()
        onEvent(FormEvent.onSubmit, {mfaType: document.activeElement.id})
    }

    const cancel = e => {
        e.preventDefault()
        onEvent(FormEvent.onCancel)
    }

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg px-4 py-8">
            <h1 className="text-center text-xl font-bold mb-4 text-gray-900">Authentication options</h1>
            <form onSubmit={submit}>
                <div className="flex justify-between items-center w-full mb-2">
                    <button
                        id={MfaType.TOTP}
                        type="submit"
                        className="w-full text-blue-500 bg-white border-2 border-gray-100 hover:border-blue-500 font-medium rounded-lg px-4 py-2">
                        Authenticator app
                    </button>
                </div>
                <div className="flex justify-between items-center w-full mb-4">
                    <button
                        id={MfaType.SMS}
                        type="submit"
                        className="w-full text-blue-500 bg-white border-2 border-gray-100 hover:border-blue-500 font-medium rounded-lg px-4 py-2">
                        Text message
                    </button>
                </div>
                <div className="flex justify-center items-center w-full">
                    <button type="button" className="text-white bg-gray-500 hover:bg-gray-600 font-medium rounded-lg py-2 px-4" onClick={cancel}>Cancel</button>
                </div>
                <div className="text-center text-red-500 mb-4">{error}</div>
            </form>
        </div>
    )
}

const LoadingSubmitButton = ({ text, isLoading }) => {
    return (
        <button
            className="text-white bg-blue-500 hover:bg-gray-600 font-medium rounded-lg px-4 py-2 text-center inline-flex items-center"
            type="submit"
            disabled={isLoading}>
            {isLoading ? 'Loading...' : text}
            {isLoading && <Spinner button />}
        </button>
    )
}

export default LoginPage
