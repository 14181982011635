import React, {useLayoutEffect, useRef, useState} from "react";
import {classNames} from "../helpers/helpers";
import {getDateString, toCurrency,} from "../utils";
import {
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/solid";

const defaultColumns = [
        {
            v: d => d.debtor?.name || "-",
            label: "Debtor"
        },
        {
            v: d => d.debtor?.bankAccountNumber || "-",
            label: "Debtor IBAN"
        },
        {
            v: d => d.creditor?.name || "-",
            label: "Creditor"
        },
        {
            v: d => d.creditor?.bankAccountNumber || "-",
            label: "Creditor IBAN"
        },
        {v: d => d.creditorReference || "-", label: "Reference"},
        {v: d => toCurrency(d.amount), label: "Amount"},
        {v: d => getDateString(d.bookingDate), label: "Date"},
    ],
    defaultOrderBy = "createdAt",
    defaultOrderByDirection = "desc"

export default function DataTable({
                                      isLoadingData,
                                      result,
                                      selectedItems,
                                      setSelectedItems,
                                  }) {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const items = result?.results || []

    useLayoutEffect(() => {
        const isIndeterminate = selectedItems.length > 0 && selectedItems.length < items.length
        setChecked(items.length > 0 && selectedItems.length === items.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedItems, items])

    function toggleAll() {
        setSelectedItems(checked || indeterminate ? [] : items)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
            }
        }
    }

    return (
        <div className="inline-block min-w-full align-middle overflow-x-auto">
            <div className="relative overflow-hidden">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                            <input
                                type="checkbox"
                                disabled={isLoadingData}
                                className={classNames(
                                    "absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300",
                                    isLoadingData ? "text-gray-400" : "text-primary-600 focus:ring-primary-600"
                                )}
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                            />
                        </th>
                        {columns.map((col, i) => (
                            <th key={col.label} scope="col"
                                className={classNames(
                                    "text-left text-sm font-semibold text-gray-900",
                                    /*i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" :*/ "px-3 py-3.5"
                                )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {items?.map((item, i) => (
                        <tr key={i}
                            onClick={() =>
                                !isLoadingData && setSelectedItems(
                                    !selectedItems.includes(item)
                                        ? [...selectedItems, item]
                                        : selectedItems.filter((p) => p !== item),
                                )}
                            className={classNames(
                                "hover:bg-gray-50",
                                selectedItems.includes(item) ? 'bg-gray-50' : undefined,
                                isLoadingData ? "" : "cursor-pointer"
                            )}>
                            <td className="relative px-7 sm:w-12 sm:px-6">
                                {selectedItems.includes(item) && (
                                    <div
                                        className="absolute inset-y-0 left-0 w-0.5 bg-primary-600"/>
                                )}
                                <input
                                    type="checkbox"
                                    disabled={isLoadingData}
                                    className={classNames(
                                        "absolute left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300",
                                        isLoadingData ? "text-gray-400" : "text-primary-600 focus:ring-primary-600"
                                    )}
                                    value={item.id}
                                    checked={selectedItems.includes(item)}
                                    onChange={(e) =>
                                        setSelectedItems(
                                            e.target.checked
                                                ? [...selectedItems, item]
                                                : selectedItems.filter((p) => p !== item),
                                        )
                                    }
                                />
                            </td>
                            {columns.map((col, i) => (
                                <td key={col.label}
                                    className={classNames(
                                        "whitespace-nowrap text-sm",
                                        /*i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" :*/ "px-3 py-4",
                                        i === 0 && selectedItems.includes(item) ? 'text-primary-600' : 'text-gray-500',
                                    )}>
                                    {col.v(item, selectedItems.includes(item))}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>

                <nav
                    className="relative bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                >
                    <p className="text-sm text-gray-700">
                        Showing <span
                        className="font-medium">{(items?.length) || "0"}</span> items
                    </p>
                </nav>
            </div>
        </div>
    )
};
