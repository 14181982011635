import React, {useEffect, useState} from "react";
import {useField} from "react-final-form";
import TextField from "@material-ui/core/TextField";
import {dateFormatter} from "../helpers/helpers";
import {parsePlainDate} from "../utils";
import useInputStyles from "./InputStyles";

const LabeledDateInput = ({defaultValue = new Date(), ...props}) => {
    const classes = useInputStyles()

    const { input: { value, onChange } } = useField(props.source)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const date = parsePlainDate(value) || dateFormatter(value || defaultValue) || value
        if (date) setInputValue(date)
        if (date && !value) onChange(date)
    }, [value, defaultValue, onChange])

    return (
        <TextField
            variant={props.variant}
            className={classes.textField}
            type="date"
            size="small"
            value={inputValue}
            label={props.label || props.source}
            helperText={false}
            InputProps={{classes: { input: classes.input }}}
            InputLabelProps={{shrink: true}}
            onChange={onChange}/>
    )
}

export default LabeledDateInput
