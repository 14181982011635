import React from "react";
import {Create, ReferenceInput, SaveButton, SelectInput, FormWithRedirect, NumberInput} from "react-admin";
import {connect, useDispatch} from 'react-redux';
import TaxNumberLookup from "../components/TaxNumberLookup2";
import {LanguageInput, PartnerSinceInput} from "../components/selectInputs";
import CompanyDataInput from "./CompanyDataInput";
import {updateCompanyData} from "../actions/companyLookupAction";
import {Box, Card, CardContent, Toolbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
    toolbar: {
        backgroundColor: '#f5f5f5',
    }
});

const mapStateToProps = state => {
    return {
        companyData: state.companyData,
    };
};

const InvestmentCompanyCreate = (props) => {
    const dispatch = useDispatch();

    const onTaxNumberChanged = () => {
        dispatch(updateCompanyData({}));
    };

    return (
        <Create {...props} title="Add company" component="div">
            <InvestmentCompanyCreateForm {...props} onTaxNumberChanged={onTaxNumberChanged} />
        </Create>
    );
};

const InvestmentCompanyCreateForm = props => {
    const classes = useStyles();

    return (
        <FormWithRedirect
            {...props}
            render={formProps => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display="flex">
                                <Box flex={1}>
                                    <TaxNumberLookup variant="outlined" {...props} source="taxNumber" onTaxNumberChanged={props.onTaxNumberChanged}/>
                                    <CompanyDataInput {...props} label="Name" source="shortName" />
                                    <CompanyDataInput {...props} source="address" />
                                    <CompanyDataInput fullWidth={false} {...props} source="postCode" />
                                    <CompanyDataInput {...props} source="city" />
                                    <ReferenceInput label="Country" source="country.id" reference="countries" defaultValue="26">
                                        <SelectInput variant="outlined" helperText={false} optionText="name"/>
                                    </ReferenceInput><br/>
                                    <PartnerSinceInput variant="outlined"/><br/>
                                    <LanguageInput variant="outlined" source="data.language" label="Default language"/>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar className={classes.toolbar}>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <SaveButton
                                    transform={data => ({ ...data, name: data.shortName })}
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                            </Box>
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

export default connect(mapStateToProps)(InvestmentCompanyCreate);