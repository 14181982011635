import React, {Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {CheckIcon, ChevronDownIcon, ChevronUpIcon, PencilIcon} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {getDateString, toDecimal} from "../utils";
import {TYPE} from "../promissory-notes/consts";
import {useLoginView} from "../login/LoginPage";
import {Dialog, Transition} from '@headlessui/react'
import {getIdToken} from "firebase/auth";
import {firebaseAuth} from "../firebase";
import Spinner from "../components/spinner";

const entryActions = (d) => {
    return (
        <div className="inline-flex space-x-3">
            <a
                href={"#/queue/" + d.id}
                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300"
            ><PencilIcon className="h-3 w-3" aria-hidden="true"/></a>

        </div>
    )
}
const defaultColumns = [
        {
            v: (d, selected) => d.promissoryNoteSnapshot?.number ? (
                <a
                    href={"#/promissory-notes/" + d.promissoryNoteRef?._path?.segments[3]}
                    className={selected ? "underline text-primary-600" : "underline"}
                >{d.promissoryNoteSnapshot?.number}</a>
            ) : "-", label: "Note #", sort: "number"
        },
        {
            v: d => d.promissoryNoteSnapshot?.debtor?.shortName || d.promissoryNoteSnapshot?.debtor?.name || "-",
            label: "Debtor"
        },
        {
            v: d => d.promissoryNoteSnapshot?.transactionDate?._seconds ? getDateString(new Date(d.promissoryNoteSnapshot.transactionDate._seconds * 1000)) : "-",
            label: "Transaction Date",
            sort: "date"
        },
        {v: d => d.promissoryNoteSnapshot?.amount?.formatted || "-", label: "Amount", sort: "amount"},
        {
            v: d => d.promissoryNoteSnapshot?.assignee?.shortName || d.promissoryNoteSnapshot?.assignee?.name || "-",
            label: "Assignee"
        },
        {v: d => TYPE[d.promissoryNoteSnapshot?.type] || "-", label: "Type", sort: "type"},
        {v: d => d.investmentInterestRate ? toDecimal(d.investmentInterestRate) : "-", label: "Invest. Rate"},
        {v: d => d.financingSource || "-", label: "Fin. Source"},
        {v: d => d.protectionType || "-", label: "Protection"},
        {v: d => d.status, label: "Status"},
        {v: d => entryActions(d), label: "Actions"},
    ],
    defaultOrderBy = "createdAt",
    defaultOrderByDirection = "desc"

const limit = 20;

export const QueueList = props => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [offset, setOffset] = useState(0)
    const logout = useLogout();
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const items = result?.results || []
    const [isStatusFilterOn, setIsStatusFilterOn] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const [renderView, isLoggedIn] = useLoginView(approveBatch, "/queue")

    useLayoutEffect(() => {
        const isIndeterminate = selectedItems.length > 0 && selectedItems.length < items.length
        setChecked(items.length > 0 && selectedItems.length === items.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedItems, items])

    function toggleAll() {
        setSelectedItems(checked || indeterminate ? [] : items)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    useEffect(() => {
        fetchList()
    }, [orderBy, orderByDirection, offset, isStatusFilterOn])

    function fetchList() {
        setIsLoadingData(true)
        sharedFetch(`admin/finance/queue?limit=${limit}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}&filterByStatus=${isStatusFilterOn ? "APPROVED" : "PENDING"}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then((json) => {
            console.log(json)
            setResult(json)
            setSelectedItems([])
            //setFilterCount(json?.totalOverdue || 0)
        }).finally(() => {
            setIsLoadingData(false)
        })
    }

    function toggleFilter(status) {
        return (e) => {
            e.preventDefault();
            const isFilterOnNewState = !isStatusFilterOn
            setIsStatusFilterOn(isFilterOnNewState)
        }
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
            }
        }
    }

    async function approveBatch() {
        const token = await getIdToken(firebaseAuth.currentUser, true)

        const approve = []
        for (let i = 0; i < selectedItems.length; i++) {
            approve.push(selectedItems[i].id)
        }

        setTimeout(() => {
            closeModal()
            sharedFetch(`admin/finance/queue/approve`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    approve: approve
                })
            }).then(() => {
                setSelectedItems([])
                fetchList()
            }).catch(e => {
                if (e.status === 401) {
                    logout();
                }
            })
        }, 1000)

    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Queue</h1>
                            <div className="sm:px-8 inline-flex">
                                <button
                                    type="button"
                                    onClick={toggleFilter("APPROVED")}
                                    className={classNames(
                                        "relative h-8 inline-flex rounded-full items-center text-sm font-medium box-border border-2 bg-gray-100 text-gray-700 transition-all",
                                        isStatusFilterOn ? "pl-7 pr-3 border-current" : "px-3 border-transparent"
                                    )}>
                                    {isStatusFilterOn && (
                                        <div className="absolute left-0 top-0 bottom-0 flex items-center pl-1.5">
                                            <CheckIcon className="w-5 h-5 text-green-600"/>
                                        </div>
                                    )}
                                    Show Archived
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div
                                    className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    {selectedItems.length > 0 && (
                                        <div
                                            className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-gray-50 pr-16 -ml-1">
                                            <button
                                                onClick={openModal}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                Approve for Investments
                                            </button>
                                        </div>
                                    )}

                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                <input
                                                    type="checkbox"
                                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                                    ref={checkbox}
                                                    checked={checked}
                                                    onChange={toggleAll}
                                                />
                                            </th>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        /*i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" :*/ "px-3 py-3.5"
                                                    )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {items?.map(item => (
                                            <tr key={item.id}
                                                className={selectedItems.includes(item) ? 'bg-gray-50' : undefined}>
                                                <td className="relative px-7 sm:w-12 sm:px-6">
                                                    {selectedItems.includes(item) && (
                                                        <div
                                                            className="absolute inset-y-0 left-0 w-0.5 bg-primary-600"/>
                                                    )}
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                                        value={item.id}
                                                        checked={selectedItems.includes(item)}
                                                        onChange={(e) =>
                                                            setSelectedItems(
                                                                e.target.checked
                                                                    ? [...selectedItems, item]
                                                                    : selectedItems.filter((p) => p !== item),
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            "whitespace-nowrap text-sm",
                                                            /*i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" :*/ "px-3 py-4",
                                                            i === 0 && selectedItems.includes(item) ? 'text-primary-600' : 'text-gray-500',
                                                        )}>
                                                        {col.v(item, selectedItems.includes(item))}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    {/*<a href="#" className="text-gray-600 hover:text-gray-900">
                                                        Edit<span className="sr-only"/>
                                                    </a>*/}
                                                    {/*<button
                                                        type="button"
                                                        onClick={download(item.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <DownloadIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>*/}
                                                    {/*<button
                                                        type="button"
                                                        onClick={deleteEntry(item.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <TrashIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>*/}
                                                    {/*<a
                                                        href={"#/invoices/" + item.id}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="relative bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            {isLoadingData ? (
                                                <div className="absolute top-0 bottom-0 left-0 flex items-center pl-4">
                                                    <Spinner unstyled className="w-4 h-4 text-gray-400"
                                                             aria-hidden="true"/>
                                                </div>
                                            ) : (
                                                <p className="text-sm text-gray-700">
                                                    Showing <span
                                                    className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                    className="font-medium">{result?.offset + result?.count || "0"}</span> of{' '}
                                                    <span className="font-medium">{result?.total || "0"}</span> results
                                                </p>
                                            )}

                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Reauthenticate
                                    </Dialog.Title>
                                    {isOpen && (renderView())}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
};
