import {classNames} from "../helpers/helpers";
import React from 'react';

const Spinner = props => {
    return (
        <div className={classNames(
            "spinner-wrapper" + (props.button ? ' spinner-wrapper--button' : '') + (props.fullHeight ? ' spinner-wrapper--full-height' : ''),
            props.unstyled ? "" : "spinner-wrapper--default",
            props.className || ''
        )}>
            <div className="spinner"/>
        </div>
    );
}

export default Spinner;
