import React, {useEffect, useState} from "react";
import {useForm} from "react-final-form";
import {useSelector} from "react-redux";
import {SelectInput, TextInput, useSaveContext} from "react-admin";
import {NoShadowInput, NoShadowNumberInput} from "../components/NoShadowInput";
import {emptySelectionItem, factoringProtectionTypes} from "../investments/ProtectionTypeInput";
import {updateCustomerPriceListData} from "../actions/customerPriceListAction";

export const financingSourceOptions = [
    { id: 'p2p', name: 'Nekster P2P' },
    { id: 'oc', name: 'Own Capital' },
    { id: 'ec', name: 'Eurocom' },
    { id: 'ps', name: 'Prva skladi' },
    { id: 'ppd', name: 'Prva pokojninska družba' },
    { id: 'poz', name: 'Prva osebna zavarovalnica' },
]

const PriceList = props => {
    const { saving } = useSaveContext()
    const form = useForm()

    const priceList = useSelector(state => state.customerPriceList.prices)
    const [reverseFactoring, setReverseFactoring] = useState(null)
    const [factoring, setFactoring] = useState(null)

    useEffect(() => {
        updateCustomerPriceListData({})
        setReverseFactoring(null)
        setFactoring(null)
    }, []);

    useEffect(() => {
        if (saving) return

        form.change('priceList.reverseFactoring', priceList?.find(p => p.type === 'RF'))
        form.change('priceList.factoring', priceList?.filter(p => p.type === 'F'))

        const isPriceListInProps = props.record.hasOwnProperty('priceList')
        const latestPriceList = isPriceListInProps ? props.record.priceList : form.getState().values?.priceList

        setReverseFactoring(latestPriceList?.reverseFactoring)
        setFactoring(latestPriceList?.factoring)
    }, [form, priceList, props.record, saving]);

    return (
        <>
            {reverseFactoring && <>
                <span style={{fontWeight: 'bold'}}>Reverse factoring price</span>

                <div style={{width: "100%"}}>
                    <PriceListNumberInput source="priceList.reverseFactoring.insurance" label="Insurance" /><HorizontalSpacer />
                    <PriceListNumberInput source="priceList.reverseFactoring.interestRate" label="Interest rate" /><HorizontalSpacer />
                    <PriceListNumberInput source="priceList.reverseFactoring.euriborRate" label="Euribor rate" /><HorizontalSpacer />
                    <PriceListNumberInput source="priceList.reverseFactoring.processingFee" label="Processing fee" /><HorizontalSpacer />
                    <PriceListNumberInput source="priceList.reverseFactoring.fixedCosts" label="Fixed costs" /><HorizontalSpacer />
                    <PriceListNumberInput source="priceList.reverseFactoring.additionalCosts" label="Additional costs" /><HorizontalSpacer />
                    <PriceListNumberInput source={`priceList.reverseFactoring.investmentInterestRate`} label="Investment rate" /><HorizontalSpacer />
                    <PriceListFinancingSourceInput source={`priceList.reverseFactoring.financingSource`} {...props} /><HorizontalSpacer />
                    <PriceListProtectionTypeInput source={`priceList.reverseFactoring.protectionType`} {...props} />
                </div>
            </>}

            {Array.isArray(factoring) && factoring.length > 0 && <>
                <span style={{fontWeight: "bold",}}>Factoring prices</span>
                <div style={{width: "100%"}}>
                    {factoring && factoring.map((item, index) =>
                        <div key={index}>
                            <TextInput source={`priceList.factoring[${index}].partnerId`} style={{ display: 'none' }}/>
                            <NoShadowInput disabled source={`priceList.factoring[${index}].partnerName`} label="Partner company" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].insurance`} label="Insurance" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].interestRate`} label="Interest rate" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].euriborRate`} label="Euribor rate" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].processingFee`} label="Processing fee" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].fixedCosts`} label="Fixed costs" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].additionalCosts`} label="Additional costs" /><HorizontalSpacer />
                            <PriceListNumberInput source={`priceList.factoring[${index}].investmentInterestRate`} label="Investment rate" /><HorizontalSpacer />
                            <PriceListFinancingSourceInput source={`priceList.factoring[${index}].financingSource`} {...props} /><HorizontalSpacer />
                            <PriceListProtectionTypeInput source={`priceList.factoring[${index}].protectionType`} {...props} />
                        </div>
                    )}
                </div>
            </>}

            {!reverseFactoring && (!Array.isArray(factoring) || factoring.length === 0) &&
                <div>
                    No factoring prices yet
                </div>
            }
        </>
    )
}

const HorizontalSpacer = () => {
    return (
        <>
            &nbsp;&nbsp;&nbsp;
        </>
    )
}

const PriceListNumberInput = props => {
    return (
        <NoShadowNumberInput style={{ width: '8em' }} source={props.source} label={props.label} />
    )
}

const PriceListFinancingSourceInput = props => {
    const financingSources = [ emptySelectionItem, ...financingSourceOptions ]

    return (
        <SelectInput style={{ width: '11em' }} label="Financing source" source={props.source} helperText={false} optionValue="id" choices={financingSources} {...props} />
    )
}

const PriceListProtectionTypeInput = props => {
    const protectionTypes = [ emptySelectionItem, ...factoringProtectionTypes ]

    return (
        <SelectInput style={{ width: '0em' }} label="Protection" source={props.source} helperText={false} optionValue="id" choices={protectionTypes} {...props} />
    )
}

export default PriceList
